import { useCallback } from 'react';
import _ from 'lodash';
import querystring from 'query-string';
import {
  HandleLink,
  GNB_REMODELING_EVENT,
  GNB_REMODELING_GOODS,
  GNB_REMODELING_REHOUSE,
  GNB_REMODELING_SECTION,
  GNB_REMODELING_SHOP,
  GNB_REMODELING_START,
  TYPE_VALUE_MOBILE,
  VIEW_TYPE_REMODELING,
  VIEW_TYPE_HOMEIDEA,
  VIEW_TYPE_MALL,
  DisplayMallGnbV5,
  NewDisplayHandleLink,
  NewDisplayHandleAction,
} from '@display-component/shared';
import { useRouter } from 'next/router';
import useGnb from './useGnb';
import TrackingService from '@/services/tracking';
import { authGateway } from '@/di-container/auth/gateway/client';
import { useAlert, useConfirm, useToast } from '@design-system/pc';
import { ALERT_Z_INDEX, CONFIRM_Z_INDEX, TOAST_Z_INDEX } from '@/styles';

export type HandleOpenAppArgs = {
  target?: string;
  channel?: string;
  module?: string;
  value?: string;
};

const useDisplayComponent = () => {
  const router = useRouter();
  const handleOpenUrl = useCallback(
    (url = '', wiseLogParam?: string) => {
      const targetUrl = TrackingService.combineWiseLogParam(
        url.startsWith('http')
          ? url
          : `${process.env.NEXT_PUBLIC_WEB_HOST}${
              url.startsWith('/') ? '' : '/'
            }${url}`,
        wiseLogParam,
      );

      if (typeof window !== 'undefined') {
        window.location.href = targetUrl;
      } else {
        router.push(targetUrl);
      }
    },
    [router],
  );

  const { gnbList: remodelingGnbMenuList } = useGnb({
    viewType: VIEW_TYPE_REMODELING,
    typeValue: TYPE_VALUE_MOBILE,
  });
  const handleAppLinkGnbRemodeling = useCallback<HandleLink>(
    (appLink) => {
      const found = _.find(remodelingGnbMenuList, {
        dispTemplateNo: appLink?.appLinkVal,
      });
      if (found) {
        const { module: gnbModule } = found as DisplayMallGnbV5;
        switch (gnbModule) {
          case GNB_REMODELING_SECTION:
            handleOpenUrl('/main', appLink?.wiseLogParam);
            break;
          case GNB_REMODELING_GOODS:
            handleOpenUrl('/goods', appLink?.wiseLogParam);
            break;
          case GNB_REMODELING_SHOP:
            handleOpenUrl('/shop/search', appLink?.wiseLogParam);
            break;
          case GNB_REMODELING_START:
            handleOpenUrl('/remodeling-start', appLink?.wiseLogParam);
            break;
          case GNB_REMODELING_EVENT:
            handleOpenUrl('/event', appLink?.wiseLogParam);
            break;
          case GNB_REMODELING_REHOUSE:
            handleOpenUrl('/rehouse', appLink?.wiseLogParam);
            break;
          default:
            handleOpenUrl(`/main?module=${gnbModule}`, appLink?.wiseLogParam);
            break;
        }
      } else {
        handleOpenUrl(`/`, appLink?.wiseLogParam);
      }
    },
    [handleOpenUrl, remodelingGnbMenuList],
  );
  const handleAppLinkCateRemodeling = useCallback<HandleLink>(
    (appLink) => {
      const query = querystring.stringify({
        ctgNo: appLink?.appLinkVal,
      });
      handleOpenUrl(`/goods?${query}`, appLink?.wiseLogParam);
    },
    [handleOpenUrl],
  );

  const { gnbList: mallGnbMenuList } = useGnb({
    viewType: VIEW_TYPE_MALL,
    typeValue: TYPE_VALUE_MOBILE,
  });
  const handleAppLinkGnbMall = useCallback<HandleLink>(
    (appLink) => {
      const found = _.find(mallGnbMenuList, {
        dispTemplateNo: appLink?.appLinkVal,
      });
      if (found) {
        const { module: gnbModule } = found as DisplayMallGnbV5;
        switch (gnbModule) {
          default:
            handleOpenUrl(
              `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mall.html?module=${gnbModule}`,
              appLink?.wiseLogParam,
            );
            break;
        }
      } else {
        handleOpenUrl(
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mall.html`,
          appLink?.wiseLogParam,
        );
      }
    },
    [handleOpenUrl, mallGnbMenuList],
  );
  const handleAppLinkCateMall = useCallback<HandleLink>(
    (appLink) => {
      if (appLink && appLink.appLinkOption) {
        const categoryClass =
          appLink.appLinkOption.ctgDepth === '2'
            ? 'L'
            : appLink.appLinkOption.ctgDepth === '3'
            ? 'M'
            : 'S';
        const query = querystring.stringify({
          ctgrNo: appLink.appLinkOption.ctgNo,
          categoryall: `${categoryClass}${appLink.appLinkOption.ctgNo}`,
        });

        handleOpenUrl(
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/category/goHsm${categoryClass}ctgy.do?${query}`,
          appLink?.wiseLogParam,
        );
      } else {
        handleOpenUrl(
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}`,
          appLink?.wiseLogParam,
        );
      }
    },
    [handleOpenUrl],
  );

  const { gnbList: homeIdeaGnbMenuList } = useGnb({
    viewType: VIEW_TYPE_HOMEIDEA,
    typeValue: TYPE_VALUE_MOBILE,
  });

  const handleAppLinkGnbHomeIdea = useCallback<HandleLink>(
    (appLink) => {
      const found = _.find(homeIdeaGnbMenuList, {
        dispTemplateNo: appLink?.appLinkVal,
      });
      if (found) {
        const { module: gnbModule } = found as DisplayMallGnbV5;
        switch (gnbModule) {
          default:
            handleOpenUrl(
              `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/interior.html?module=${gnbModule}`,
              appLink?.wiseLogParam,
            );
            break;
        }
      } else {
        handleOpenUrl(
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/interior.html`,
          appLink?.wiseLogParam,
        );
      }
    },
    [handleOpenUrl, homeIdeaGnbMenuList],
  );

  const handleLink = useCallback<HandleLink>(
    (appLink) => {
      if (appLink?.appLinkTarget === 'LINK') {
        if (appLink?.appLinkVal) {
          handleOpenUrl(appLink.appLinkVal, appLink?.wiseLogParam);
        }
      }
      if (appLink?.appLinkTarget === 'GNB') {
        switch (appLink?.appLinkChannel) {
          case 'MALL':
            handleAppLinkGnbMall(appLink);
            break;
          case 'HOMEIDEA':
            handleAppLinkGnbHomeIdea(appLink);
            break;
          default:
            handleAppLinkGnbRemodeling(appLink);
            break;
        }
      }
      if (appLink?.appLinkTarget === 'CATE') {
        switch (appLink?.appLinkChannel) {
          case 'MALL':
            handleAppLinkCateMall(appLink);
            break;
          default:
            handleAppLinkCateRemodeling(appLink);
            break;
        }
      }
      if (appLink?.appLinkTarget === 'PLAN') {
        if (appLink?.appLinkVal) {
          handleOpenUrl(
            `/event/${appLink.appLinkVal}?eventStatusType=ING`,
            appLink?.wiseLogParam,
          );
        }
      }
    },
    [
      handleAppLinkCateMall,
      handleAppLinkCateRemodeling,
      handleAppLinkGnbHomeIdea,
      handleAppLinkGnbMall,
      handleAppLinkGnbRemodeling,
      handleOpenUrl,
    ],
  );

  const handleNewAppLinkGnbHomeIdea = useCallback<NewDisplayHandleLink>(
    (appLink) => {
      if (appLink?.applink?.linkUrl) {
        handleOpenUrl(appLink.applink.linkUrl);
      } else {
        handleOpenUrl(
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/interior.html`,
        );
      }
    },
    [handleOpenUrl],
  );

  const handleNewAppLinkGnbRemodeling = useCallback<NewDisplayHandleLink>(
    (appLink) => {
      if (appLink?.applink?.linkUrl) {
        handleOpenUrl(appLink.applink.linkUrl);
      } else {
        handleOpenUrl(
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/interior.html`,
        );
      }
    },
    [handleOpenUrl],
  );

  const handleNewAppLinkGnbMain = useCallback<NewDisplayHandleLink>(() => {
    handleOpenUrl(`${process.env.NEXT_PUBLIC_WEB_HOST}`);
  }, [handleOpenUrl]);

  const handleNewAppLinkGnbMall = useCallback<NewDisplayHandleLink>(
    (appLink) => {
      if (appLink?.applink?.linkUrl) {
        handleOpenUrl(appLink.applink.linkUrl);
      } else {
        handleOpenUrl(`${process.env.NEXT_PUBLIC_WEB_HOST}/furnishing`);
      }
    },
    [handleOpenUrl],
  );

  const handleNewAppLinkCate = useCallback<NewDisplayHandleLink>(
    (appLink) => {
      if (appLink?.applink?.linkUrl) {
        handleOpenUrl(appLink.applink.linkUrl);
      }
    },
    [handleOpenUrl],
  );

  const handleNewLink = useCallback<NewDisplayHandleLink>(
    (appLink) => {
      console.log('[TEST-DISPLAY - appLink]', appLink);
      if (
        (appLink?.applink && !appLink.applink.appLinkTarget) ||
        appLink?.applink?.appLinkTarget === 'URL'
      ) {
        handleOpenUrl(appLink.linkUrl);
      }

      if (appLink?.applink?.appLinkTarget === 'GNB') {
        switch (appLink?.applink?.appLinkChannel) {
          case 'MAIN':
            handleNewAppLinkGnbMain(appLink);
            break;
          case 'MALL':
            handleNewAppLinkGnbMall(appLink);
            break;
          case 'HOMEIDEA':
            handleNewAppLinkGnbHomeIdea(appLink);
            break;
          case 'REMODELING':
            handleNewAppLinkGnbRemodeling(appLink);
            break;
          default:
            handleOpenUrl(appLink.linkUrl);
            break;
        }
      }
      if (appLink?.applink?.appLinkTarget === 'CATEGORY') {
        switch (appLink?.applink?.appLinkChannel) {
          case 'MALL':
            handleNewAppLinkCate(appLink);
            break;

          case 'HOMEIDEA':
            handleNewAppLinkCate(appLink);
            break;

          default:
            handleOpenUrl(appLink.linkUrl);
            break;
        }
      }

      if (appLink?.applink?.appLinkTarget === 'PLAN') {
        handleOpenUrl(
          `${appLink?.linkUrl}${
            appLink.applink.appLinkAddQuery
              ? '&' + appLink.applink.appLinkAddQuery
              : ''
          }`,
        );
      }

      if (appLink?.goodsInfo) {
        handleOpenUrl(
          `${process.env.NEXT_PUBLIC_WEB_HOST}/goods/${appLink.goodsInfo.gdsNo}`,
        );
      }
    },
    [
      handleOpenUrl,
      handleNewAppLinkGnbMain,
      handleNewAppLinkGnbMall,
      handleNewAppLinkGnbHomeIdea,
      handleNewAppLinkGnbRemodeling,
      handleNewAppLinkCate,
    ],
  );

  const handleLogin = useCallback(async () => {
    await authGateway().login.exec();
  }, []);

  const { toast } = useToast();

  const { handleOpen: handleAlertOpen } = useAlert();
  const { handleOpen: handleConfirmOpen, handleClose: closeConfirm } =
    useConfirm();

  const handleAction = useCallback<NewDisplayHandleAction>(
    ({ message, action, type }) => {
      console.log('[action]', { message, action, type });
      switch (type) {
        case 'toast':
          toast.success(message, {
            alignment: 'center',
            placement: 'bottom',
            variant: 'basic',
            offset: 87,
            zIndex: TOAST_Z_INDEX,
          });
          break;

        case 'alert':
          handleAlertOpen({ content: message, zIndex: ALERT_Z_INDEX });
          break;

        case 'confirm':
          if (action === 'login') {
            console.log('[confirm]');
            handleConfirmOpen({
              cancelText: '취소',
              confirmText: '로그인',
              content: message,
              width: '370px',
              isShowCloseButton: true,
              zIndex: CONFIRM_Z_INDEX,
              handleConfirm: () => {
                handleLogin();
              },
              handleCloseButton: () => {
                closeConfirm();
              },
            });
          } else {
            handleConfirmOpen({
              cancelText: '취소',
              confirmText: '로그인',
              content: message,
              width: '370px',
              zIndex: CONFIRM_Z_INDEX,
            });
          }
          break;

        default:
          break;
      }
    },
    [closeConfirm, handleAlertOpen, handleConfirmOpen, handleLogin, toast],
  );

  return {
    handleLink,
    handleNewLink,
    handleAction,
  };
};

export default useDisplayComponent;
