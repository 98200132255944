import type { HttpClient } from '@store/base';
import {
  GetCategoryFilterRequest,
  GetCategoryFilterResponse,
  GetCurrentCategoryRequest,
  GetCurrentCategoryResponse,
  GetDisplayCategoryListResponse,
  GetGoodsCategoryRequest,
  GetGoodsCategoryResponse,
  GetNavigationsRequest,
  GetNavigationsResponse,
} from '../../model/dto/category';

// 리모델링 전체 카테고리 리스트
export class GetGoodsCategoryGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetGoodsCategoryRequest) {
    return await this.httpClient.request<GetGoodsCategoryResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/categories/cascade`,
      body: req,
    });
  }
}

export class GetMallCategoryGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetGoodsCategoryRequest) {
    return await this.httpClient.request<GetGoodsCategoryResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/categories/hs-mall/cascade`,
      body: req,
    });
  }
}

export class GetDisplayCategoryList {
  constructor(readonly httpClient: HttpClient) {}

  async exec() {
    return await this.httpClient.request<GetDisplayCategoryListResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/categories/display-category-list`,
      body: { excludeSpceCtgYN: 'N' },
    });
  }
}

// 카테고리 네비게이션
export class GetNavigationsGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetNavigationsRequest) {
    return await this.httpClient.request<GetNavigationsResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/display-service/api/v1/categories/${req.categoryNo}/navigations?isShowAllCategory=${req.isShowAllCategory}`,
    });
  }
}

// 카테고리 페이지 - 현재 카테고리 조회
export class GetCurrentCategoryGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetCurrentCategoryRequest) {
    return await this.httpClient.request<GetCurrentCategoryResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/display-service/api/v1/categories/${req.categoryNo}/path`,
    });
  }
}

// 카테고리페이지 카테고리 filter
export class GetCategoryFilterGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetCategoryFilterRequest) {
    return await this.httpClient.request<GetCategoryFilterResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/display-service/api/v1/categories/${req.categoryNo}/filter-items?ctgDepth=${req.ctgDepth}`,
    });
  }
}
